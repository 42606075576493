.navigation-item {
  .navigation-item--reply-enquiries {
    color: #17a2b8;
  }

  .navigation-item__indicator {
    visibility: visible;
  }
  .navigation-item__button {
    visibility: hidden;
    margin-right: 1rem;
  }

  &:hover {
    .navigation-item__indicator {
      visibility: hidden;
    }

    .navigation-item__button {
      visibility: visible;
    }
  }
}

.navigation-item__settings {
  position: relative;

  .navigation-item__button {
    visibility: hidden;
    position: absolute;
    top: 0%;
    left: 96%;
  }

  .navigation-item__indicator {
    visibility: visible;
    position: absolute;
    top: 20%;
    left: 98%;
  }

  &:hover {
    .navigation-item__indicator {
      visibility: hidden;
    }

    .navigation-item__button {
      visibility: visible;
    }
  }
}

.navigation_item--file-success {
  color: #2ad2c9;
}

.navigation_item--file-error {
  color: #d33857;
}

.navigattion_item--auth-enable {
  color: #f2994a;
}

.navigation_item--auth-disable {
  color: #bdbdbd;
}

.navigation_item--password {
  color: #f2c94c;
}

.navigation_item--certification {
  color: #2ad2c9;
}

.navigation_item--certification-watched {
  color: #f2c94c;
}

.navigation_item--company-details {
  color: #2ad2c9;
}

.navigation_item--new-enquiries {
  color: #28a745;
}

.navigation_item--reopen-enquiries {
  color: #28a745;
}

.navigation_item--resolved-enquiries {
  color: #677788;
}

.navigation-item--dropdown-menu {
  margin: 0px;
}

.navigation-container {
  height: 22.5rem;
  overflow-y: scroll;
}
