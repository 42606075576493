@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.landing-footer-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 42px 0px 32px 0px;
  flex-wrap: wrap;

  .landing-footer-section__text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: 12px;
    flex-grow: 1;

    .landing-footer-section__text__inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      @include media-breakpoint-down(md) {
        margin-bottom: 18px;
      }
    }

    a {
      color: var(--gray-800);
      margin-right: 12px;
      transition: 0.15s ease-in-out;

      @include media-breakpoint-down(xs) {
        margin-left: 0px;
        margin-right: 12px;
      }

      &:hover {
        color: var(--secondary);
      }
    }
  }
}
