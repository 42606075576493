@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.page-header-nav-item {
  position: relative;
  line-height: 3.75rem;

  &:hover {
    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: var(--secondary);
      left: 0;
      bottom: 0;
    }
  }
}

.vertical-line {
  border-left: 1px solid #b0b9bf;
  height: 18px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.page-header-nav-private {
  display: none;
  align-items: center;
  flex-direction: row;

  @media (min-width: 1280) {
    display: none;
  }

  @media (min-width: 1440px) {
    display: flex;
  }
}
