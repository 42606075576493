@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.landing-card {
  background-color: var(--light);
  width: auto;
  text-align: left;
  overflow: hidden;

  height: 100%;
  position: relative;
  background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction: column;

  &:hover {
    transition: all 1s;
    box-shadow: 8px 8px 25px rgba($color: black, $alpha: 0.2);

    .landing-card--header--img {
      transform: scale(1.1);
    }
  }

  &--container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      height: auto;
    }

    p {
      color: var(--dark);
      font-size: 22px;
      flex-grow: 1;
    }

    &--title {
      color: rgb(51, 51, 51);
      font-size: 25px;
      flex-grow: 1;
      font-weight: lighter;
    }

    &--label {
      justify-content: end;
      color: var(--primary);
      margin-bottom: 10px;
    }
  }

  &--header {
    width: 100%;
    overflow: hidden;
    position: relative;

    &--img {
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: all 2s;

      @include media-breakpoint-down(md) {
        background-position: top center;
      }
    }
  }
}
