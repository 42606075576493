@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.landing-button {
  width: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-image: linear-gradient(
    to right,
    var(--secondary) 33%,
    transparent 33% 66%,
    var(--secondary) 66%
  );
  background-size: 300% 2px;
  background-repeat: no-repeat;
  background-position: right bottom;

  &--text {
    color: var(--primary);
    font-size: 16px;
    flex-grow: 1;
    text-align: left;
    margin-left: 4px;
    cursor: pointer;
  }

  &--icon {
    transition: all 1s;
    color: var(--primary);
    transform: translate(6px, 0px);
  }

  &:hover {
    color: var(--secondary);
    transition: all 0.5s;
    background-position: left bottom;

    .landing-button--icon {
      color: var(--secondary);
    }
  }
}

.landing-button-disabled {
  width: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  :after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--secondary);
    left: 0;
    bottom: 0;
  }

  .landing-button--icon {
    color: white;
  }

  &:hover {
    transition: all 0.5s;
    background-position: left bottom;

    .landing-button--icon {
      color: var(--secondary);
    }
  }
}
