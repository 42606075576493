.react-autosuggest__container {
  position: relative;
  flex: 1;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 45px;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #ced4da;
  background-color: #fff;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:hover {
  background-color: #deebff;
  color: black !important;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #2684ff;
  color: white;
}

.form-control-search {
  min-height: 38px;
}

.was-validated .react-autosuggest__container ~ .invalid-feedback {
  display: block;
}

:local(.search__not-found) {
  min-height: 90px;
  text-align: center;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: black;
}

:local(.form-control__search) {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}
